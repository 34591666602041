import style from "./Customer.module.css";
import {Card} from "../../../components/general/card/Card";
import {useParams} from "react-router-dom";
import {useCustomerReactions} from "../../../hooks/useCustomerReactions";
import {KolInputText, KolSpin, KolTabs} from "@public-ui/react";
import {CustomerDetail} from "./CustomerDetail";
import {useEffect, useState} from "react";
import {CustomerReaction} from "../../../types/customerReaction";
import {customerReactions} from "../../../mockData/reactions";
import {Tabs} from "../../../components/general/tabs/Tabs";


export function Customer() {
    let {id} = useParams();
    const {isLoading, reactions} = useCustomerReactions();
    const [reaction, setReaction] = useState<CustomerReaction | null>(null)

    useEffect(() => {
        // let reactions = customerReactions;
        if (!isLoading && reactions.length > 0) {
            let reaction = reactions.find(r => r.ACCESSIBILITYID === id);
            console.log(reaction);
            console.log(reactions);
            if (reaction) {
                setReaction(reaction)
            }
        }
    }, [isLoading, reactions]);

    return (
        <>
            <div className={style.content}>
                <div className={style.customerInfo}>
                    <Card>
                        {isLoading
                            ? <KolSpin _show={true}/>
                            : reaction ? <CustomerDetail reaction={reaction} isCard={false}/> : ''
                        }
                    </Card>
                </div>
                <div className={style.customerDetails}>
                    <Card className={style.customerDetailsCard}>
                        {isLoading
                            ? <KolSpin _show={true}/>
                            : <Tabs tabs={[
                                {
                                    label: 'test',
                                    content: <div>
                                                <KolInputText _label={'Place'} _value={reaction?.PLACE}/>
                                                <KolInputText _label={'Zip code'} _value={reaction?.ZIPCODE}/>
                                                <KolInputText _label={'Organisation ID'} _value={reaction?.ORGANISATION_ID}/>
                                            </div>
                                },
                                {
                                    label: 'test 1',
                                    content: <>test</>
                                }
                            ]} className={'customer-tabs'}></Tabs>
                        }
                    </Card>
                </div>
            </div>
        </>
    )
}