import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import {useAuth} from './hooks/useAuth';
import {Navigate, useNavigate, useRoutes} from "react-router-dom";
import SignIn from "./pages/auth/SignIn";
import {Props} from "@public-ui/components/dist/types/components/abbr/types";
import {MANAGER_ROUTES} from "./pages/manager/ManagerRouter";
import useTheme from "./hooks/useTheme";

function App({dataTheme} : Props) {
    const {value: isAuthenticated} = useAuth();
    const {theme, setTheme} = useTheme();
    const routes = useRoutes([
        isAuthenticated ?
            {
                ...MANAGER_ROUTES
            } :
            {
                path: '/sign-in',
                element: (
                    <SignIn />
                ),
            },
        {
            path: '*',
            element: isAuthenticated ? <Navigate to='/manager'/> : <Navigate to='/sign-in'/>
        }
    ]);

    return (
        <>
            {routes}
        </>
    );
}

export default App;
