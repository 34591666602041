import {useState} from "react";
import {sideMenuState} from "../types/sideMenuState";

export function useSideContent() {
    const [sideContent, setSideContent] = useState<sideMenuState>({
        isOpen: false,
        item: {},
        width: 'auto'
    } as sideMenuState);

    const openSideContent = (item: any, width: string) => {
        setSideContent({
            isOpen: true,
            item: item,
            width: width
        })
    };

    const closeSideContent = () => {
        setSideContent({
            isOpen: false,
            item: null,
            width: 'auto'
        })
    };

    return {sideContent, openSideContent, closeSideContent};
}