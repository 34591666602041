import style from './Card.module.css';
import React, {Children, ReactNode} from "react";

type Props = {
    children: ReactNode | ReactNode[],
    className?: string
}

export function Card({children, className} : Props) {
    const childElements = Children.toArray(children);
    const count = Children.count(children);

    const renderWithHeader = (elements: any[]) => {
        const header = elements[0];
        const body = elements[1];
        return (
            <>
                <div className={style.cardHeader}>
                    {header}
                </div>
                {renderBody(body)}
            </>
        )
    };

    const renderBody = (body: any) => {
        return (
            <div className={style.cardBody}>
                {body}
            </div>
        )
    };

    return (
        <div className={`${style.card} ${className ? className : ''}`}>
            {count > 1 && renderWithHeader(childElements)}
            {count == 1 && renderBody(childElements[0])}
        </div>
    );
}