import style from './CustomerDetail.module.css';
import {KolButton, KolIcon} from "@public-ui/react";
import {Avatar} from "../../../components/general/avatar/Avatar";
import {DropDownArea, Position} from "../../../components/general/drop-down/DropDownArea";
import {MultiMenuList} from "../../../components/general/navigation/MultiMenuList";
import {CustomerReaction} from "../../../types/customerReaction";

type Props = {
    reaction: CustomerReaction,
    onCloseClick?: (e: any) => void,
    isCard?: boolean
};

export function CustomerDetail({reaction, onCloseClick, isCard = true} : Props) {

    const onCloseClickHandler = (e: any) => {
        if (onCloseClick) {
            onCloseClick(e);
        }
    };

    return (
        <div className={style.reactionDetail}>
            <div className={style.header}>
                <div>
                    <h4>Customer Reaction - preview</h4>
                </div>
                {isCard ?
                <div>
                    <a className={style.closeButton} title={'Close button'} onClick={onCloseClickHandler}>
                        <KolIcon _icons={'codicon codicon-close'} />
                    </a>
                </div> : '' }
            </div>

            <div className={style.content}>
                <section>
                    <div className={`${style.avatarContainer}`}>
                        <Avatar name={reaction.FIRSTNAME + ' ' + reaction.LASTNAME} width={'100px'} height={'120px'} squared={true} />
                    </div>
                    <div>
                        <div className={`${style.heading} ${style.sectionContent}`}>
                            <h5>{reaction.FIRSTNAME} {reaction.LASTNAME}</h5>
                            <a className={style.circleButton} href='javascript:void(0)' onClick={onCloseClickHandler}>
                                <KolIcon _icons={'codicon codicon-edit'} />
                            </a>
                        </div>
                        <div className={`${style.actions} ${style.sectionContent}`}>
                            <KolButton _label={''} _icons={'codicon codicon-folder'} _hideLabel={true} _variant={'primary'} />
                            <KolButton _label={''} _icons={'codicon codicon-watch'} _hideLabel={true} _variant={'primary'} />
                            <KolButton _label={''} _icons={'codicon codicon-edit'} _hideLabel={true} _variant={'primary'} />
                            <DropDownArea
                                button={<KolButton _label={''} _icons={'codicon codicon-kebab-vertical'} _hideLabel={true} _variant={'primary'} />}
                                position={Position.OVER}
                            >
                                <MultiMenuList items={[
                                    {
                                        href: 'javascript:void(0)',
                                        label: 'Edit defaults',
                                        icon: 'codicon codicon-edit'
                                    },
                                    {
                                        href: 'javascript:void(0)',
                                        label: 'New offer',
                                        icon: 'codicon codicon-preview'
                                    },
                                    {
                                        href: 'javascript:void(0)',
                                        label: 'Customer base sheet',
                                        icon: 'codicon codicon-file'
                                    },
                                    {
                                        href: 'javascript:void(0)',
                                        label: 'New task',
                                        icon: 'codicon codicon-book'
                                    },
                                    {
                                        href: 'javascript:void(0)',
                                        label: 'New appointment',
                                        icon: 'codicon codicon-calendar'
                                    },
                                    {
                                        href: 'javascript:void(0)',
                                        label: 'Add to campaign',
                                        icon: 'codicon codicon-organization'
                                    },
                                    {
                                        href: 'javascript:void(0)',
                                        label: 'New email',
                                        icon: 'codicon codicon-mention'
                                    },
                                    {
                                        href: 'javascript:void(0)',
                                        label: 'New letter',
                                        icon: 'codicon codicon-calendar'
                                    }
                                ]}
                                className={style.detailList}
                                ></MultiMenuList>
                            </DropDownArea>

                        </div>
                    </div>
                </section>
                <section>
                    <div className={style.info}>
                        <h5>Favorites</h5>
                        <dl>
                            <dt>Customer reaction code</dt>
                            <dd>{reaction.CUSTOMERREACTIONCODE}</dd>

                            <dt>Circle of people</dt>
                            <dd>{reaction.CIRCLEOFPEOPLE}</dd>

                            <dt>Customer reaction type</dt>
                            <dd>{reaction.CUSTOMERREACTIONTYPE}</dd>

                            <dt>Customer reaction status</dt>
                            <dd>{reaction.CUSTOMERREACTIONSTATUS}</dd>

                            <dt>Customer reaction code</dt>
                            <dd>{reaction.CUSTOMERREACTIONCODE}</dd>
                        </dl>

                        <h5>Communication</h5>
                        <dl>
                            <dt>Place</dt>
                            <dd>{reaction.PLACE}</dd>

                            <dt>Zip Code</dt>
                            <dd>{reaction.CUSTOMERREACTIONCODE}</dd>

                            <dt>Organisation ID</dt>
                            <dd>{reaction.ORGANISATION_ID}</dd>
                        </dl>

                        <h5>Further information</h5>
                        <dl>
                            <dt>Date of reciept</dt>
                            <dd>{reaction.DATEOFRECEIPT}</dd>

                            <dt>Want answer</dt>
                            <dd>{reaction.WANTANSWER}</dd>
                        </dl>
                    </div>
                </section>
            </div>
        </div>
    );
}