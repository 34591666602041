import {KolAlert, KolButton, KolForm, KolInputCheckbox, KolInputDate, KolInputText, KolSelect} from "@public-ui/react";
import addReactionStyle from "../../../pages/manager/customer/AddReaction.module.css";
import {useState} from "react";
import {CustomerReaction} from "../../../types/customerReaction";
import {useFormErrors} from "../../../hooks/useFormErrors";

type Props = {
    onCancelClick?: () => void,
    onFormSubmit?: (data: CustomerReaction) => void;
};


export function AddCustomerReactionForm({onCancelClick, onFormSubmit}: Props) {
    const [data, setData] = useState({
        CUSTOMERREACTIONCODE: '',
        CIRCLEOFPEOPLE: '',
        ZIPCODE: '',
        ORGANISATION_ID: '',
        CUSTOMERREACTIONSTATUS: 'open',
        FIRSTNAME: '',
        PLACE: '',
        DATEOFRECEIPT: '',
        ACCESSIBILITYID: '',
        LASTNAME: '',
        WANTANSWER: '',
        CUSTOMERREACTIONTYPE: ''
    } as CustomerReaction);

    const [update, setUpdate] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');

    const {addError, clearError, getError, errors} = useFormErrors();
    const refresh = () => {
        setUpdate(update + 1);
    };

    const validateField = (field: string) => {
        type ObjectKey = keyof typeof data;
        const fieldVar = field.toUpperCase() as ObjectKey;

        const v = data[fieldVar];
        if (v && v.length < 1) {
            addError(field, 'This field is required');
            refresh();
            console.log(errors);
            return;
        }

        clearError(field);
        refresh();
    };

    const handleRequiredOnChange = (field: string, value: any) => {
        let data: any = {};
        data[field.toUpperCase()] = value;

        setData((prevState) => {
            const d = Object.assign(prevState, data);
            return (d)
        });

        if ((value as string).length > 0) {
            clearError(field)
            refresh();
            return;
        }
    };

    const validateAll = () => {
        const required = [
            'customerReactionCode',
            'firstName',
            'lastName',
            'dateOfReceipt',
            'circleOfPeople',
            'zipCode',
            'place',
            'organisation_id'
        ];

        required.map((field) => {
                validateField(field);
            }
        );

        return errors;
    };


    return (
        <KolForm _on={{
            onSubmit: (e) => {

            }
        }}>
            <KolInputText
                _label={'Customer Reaction Code'}
                _required={true}
                _error={getError('customerReactionCode')}
                _value={data.CUSTOMERREACTIONCODE}
                _on={{
                    onChange: (e, value) => {

                        handleRequiredOnChange('customerReactionCode', value);
                        // setData((prevState) => {
                        //     return ({
                        //         ...prevState,
                        //         CUSTOMERREACTIONCODE: value as string
                        //     })
                        // });
                        //
                        // if ((value as string).length > 0) {
                        //     clearError('customerReactionCode')
                        //     refresh();
                        //     return;
                        // }
                    },
                    onBlur: (e) => {
                        // if (data.CUSTOMERREACTIONCODE.length < 1) {
                        //     console.log('calling');
                        //     addError('customerReactionCode', 'This field is required');
                        //     refresh();
                        //     return;
                        // }
                        //
                        // clearError('customerReactionCode');
                        // refresh();
                        validateField('customerReactionCode');
                    }
                }}
            />
            <KolInputText
                _label={'First Name'}
                _required={true}
                _error={getError('firstName')}
                _value={data.FIRSTNAME}
                _on={{
                    onChange: (e, value) => {
                        handleRequiredOnChange('firstName', value);
                        // setData((prevState) => {
                        //     return ({
                        //         ...prevState,
                        //         FIRSTNAME: value as string
                        //     })
                        // });
                        //
                        // if ((value as string).length > 0) {
                        //     clearError('firstName')
                        //     refresh();
                        //     return;
                        // }
                    },
                    onBlur: (e) => {
                        validateField('firstName');
                        // if (data.FIRSTNAME.length < 1) {
                        //     addError('firstName', 'This field is required');
                        //     refresh();
                        //     return;
                        // }
                        //
                        // clearError('firstName');
                        // refresh();
                    }
                }}
            />
            <KolInputText
                _label={'Last Name'}
                _required={true}
                _value={data.LASTNAME}
                _error={getError('lastName')}
                _on={{
                    onChange: (e, value) => {
                        handleRequiredOnChange('lastName', value)
                    },
                    onBlur: () => validateField('lastName')
                }}
            />
            <KolInputDate
                _label={'Date of receipt'}
                _required={true}
                _error={getError('dateOfReceipt')}
                _on={{
                    onChange: (e, value) => {
                        console.log(value);
                        handleRequiredOnChange('dateOfReceipt', value)
                    },
                    onBlur: () => validateField('dateOfReceipt')
                }}
            />
            <KolInputText
                _label={'Circle of people'}
                _required={true}
                _error={getError('circleOfPeople')}
                _value={data.CIRCLEOFPEOPLE}
                _on={{
                    onChange: (e, value) => {
                        handleRequiredOnChange('circleOfPeople', value)
                    },
                    onBlur: () => validateField('circleOfPeople')
                }}
            />
            <KolInputText
                _label={'Zip code'}
                _required={true}
                _error={getError('zipCode')}
                _value={data.ZIPCODE}
                _on={{
                    onChange: (e, value) => {
                        handleRequiredOnChange('zipCode', value)
                    },
                    onBlur: () => validateField('zipCode')
                }}
            />
            <KolInputText
                _label={'Place'}
                _required={true}
                _error={getError('place')}
                _value={data.PLACE}
                _on={{
                    onChange: (e, value) => {
                        handleRequiredOnChange('place', value)
                    },
                    onBlur: () => validateField('place')
                }}
            />
            <KolInputText
                _label={'Organisation ID'}
                _required={true}
                _error={getError('organisation_id')}
                _value={data.ORGANISATION_ID}
                _on={{
                    onChange: (e, value) => {
                        handleRequiredOnChange('organisation_id', value)
                    },
                    onBlur: () => validateField('organisation_id')
                }}
            />
            <KolInputText
                _label={'Customer reaction type'}
                _required={false}
            />
            <KolSelect _label={'Customer reaction status'}
                       _options={[{'label': 'Open', 'value': 'open'}, {'label': 'Closed', 'value': 'closed'}]}
                       _value={data.CUSTOMERREACTIONSTATUS}
            />

            <KolInputCheckbox
                _label={'Want answer'}
                _value={'yes'}
                _on={{
                    onChange: (e, value) => {
                        setData((prevState) => {
                            return ({
                                ...prevState,
                                WANTANSWER: value as string
                            })
                        })
                    }
                }}
            />

            <div className={addReactionStyle.buttons}>
                <KolButton
                    _label={'Save'}
                    _variant={'primary'}
                    _type={'button'}
                    _on={{
                        onClick: () => {
                            const errs = Object.keys(validateAll()).length;
                            if (errs > 0) {
                                setErrorMessage('There are empty mandantory fields. You have empty ' + errs + ' mandantory fields');
                                return;
                            }

                            setErrorMessage('');
                            if (onFormSubmit) {
                                onFormSubmit(data);
                            }
                        }
                    }}
                />
                <KolButton _label={'Cancel'} _variant={'danger'}
                           _on={{
                               onClick: onCancelClick ? onCancelClick : () => {
                               }
                           }}
                />

                {errorMessage ? <KolAlert _alert={true} _label={errorMessage}/> : ''}

            </div>
        </KolForm>
    );
}