import style from './Avatar.module.css';
import React, {ForwardedRef, MouseEventHandler} from "react";

type Props = {
    image?: string,
    name?: string,
    width?: string,
    height?: string,
    background?: string,
    color?: string,
    onClick?: undefined | MouseEventHandler<HTMLDivElement>,
    squared?: boolean
};

const Avatar = React.forwardRef((
    {name, image, width, height, background, color, onClick, squared} : Props,
    ref: ForwardedRef<HTMLDivElement>
) => {
    if (!width) {
        width = '50px'; // default width
    }

    if (!height) {
        height = '50px';
    }

    if (!name) {
        name = 'Avatar';
    }


    const getNameInitials = (name: string) => {
        let names = name.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }

        return initials;
    };

    return (
        <div
            ref={ref}
            onClick={onClick}
            style={{
                width: width,
                height: height,
                borderRadius: squared ? 'inherit' : '50%',
                overflow: 'hidden',
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
                background: background ? background : 'var(--adito-avatar-primary-color)',
                cursor: onClick ? 'pointer' : 'inherit'
            }}>
            <div style={{
                fontSize: '20px',
                fontWeight: 'bold',
                color: color ? color : 'white'
            }} title={name}>
                {image ? <img src={image} width={width} alt={name} /> : getNameInitials(name)}
            </div>
        </div>
    );
})

export {Avatar};