import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export function createValueContext<T>(
  storageGetter: () => T,
  storageSetter: (value: T) => void,
) {
  const Ctx = createContext<CtxValue<T> | undefined>(undefined);

  type Props = { children: ReactNode };

  type CtxValue<T> = {
    value: T;
    setValue(value: T): void;
  };

  function ContextValueProvider({ children }: Props) {
    const [value, setValue] = useState<T>(storageGetter());

    const ctx = useMemo(() => ({ value, setValue }), [value]);

    useEffect(() => {
      storageSetter(value);
    }, [value]);

    return <Ctx.Provider value={ctx}>{children}</Ctx.Provider>;
  }

  function useContextValue() {
    const ctx = useContext(Ctx);

    if (!ctx) {
      throw new Error('not in ContextValueProvider');
    }

    return ctx;
  }

  return {
    ContextValueProvider,
    useContextValue,
  };
}
