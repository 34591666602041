import {useEffect, useRef, useState} from "react";
import style from './ListBox.module.css';
import {useOutsideClick} from "../../../hooks/useOutsideClick";

type Item = {
    label: string,
    value: any,
    id?: string
};

type Props = {
    label?: string,
    items: Item[],
    value?: Item | number
}


export function ListBox({label, items, value}: Props) {
    const listRef = useRef<HTMLUListElement>(null);
    const listBoxRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(true);
    const [width, setWidth] = useState(0);
    const {isOutsideClick, setIsOutsideClick} = useOutsideClick(listBoxRef);

    const getPreselectedItem = (items: Item[], value?: Item | number): Item => {
        if (!value) {
            return {} as Item;
        }

        let i;

        if (typeof(value) == 'number') {
            i = items.find((v, i) => i == value);
            if (i !== undefined) {
                return i;
            }
        }

        i = items.find((item) => item == value);

        if (i !== undefined) {
            return i;
        }

        return {} as Item;
    };

    const [selected, setSelected] = useState(
        getPreselectedItem(items, value)
    );

    useEffect(() => {
        if (listRef.current !== null && listBoxRef.current !== null) {
            let width = listRef.current.offsetWidth;
            setWidth(width);
            setOpen(false);
        }

        if (!label) {
            setSelected(items[0]);
        }
    }, [listBoxRef, listRef]);

    useEffect(() => {
        if (open && isOutsideClick) {
            setOpen(false);
        }
    }, [isOutsideClick]);

    return (
        <div className={style.listBox} style={{
            minWidth: width + 'px'
        }} ref={listBoxRef}>
            <span
                style={{
                    width: width + 'px'
                }}
                onClick={() => {
                setOpen(!open);
            }}>{selected?.value ? selected.label : label}</span>
            <ul className={open ? style.show : style.hidden} ref={listRef}>
                {
                    items.map((item, i) =>
                    <li
                        key={item?.id ? item.id : 'label_' + i}
                        className={(selected == item) ? style.active : ''}
                        onClick={(e) => {
                            setSelected(item);
                            setOpen(false);
                    }}>
                        {item.label}
                    </li>)
                }
            </ul>
        </div>
    );
}