// hooks/use-theme.js
import { useState, useLayoutEffect } from 'react'

const preferDarkSchema = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
const defaultTheme = preferDarkSchema ? 'default' : 'adito'

function useTheme() {
    const [theme, setTheme] = useState(localStorage.getItem('adito-theme') || defaultTheme)

    useLayoutEffect(() => {
        document.documentElement.setAttribute('data-theme-mode', theme)
        localStorage.setItem('adito-theme', theme)
    }, [theme])

    return { theme, setTheme }
}

export default useTheme;