import {KolSpin, KolTable} from "@public-ui/react";
import {useEffect, useMemo, useState} from "react";
import dashboardStyle from "./Dashboard.module.css"
import GridLayout, { Layout, WidthProvider } from 'react-grid-layout';
import {DraggableCard} from "../../../components/general/card/DraggableCard";
import {CustomerDetail} from "../customer/CustomerDetail";
import {useCustomerReactions} from "../../../hooks/useCustomerReactions";
import {customerReactions} from "../../../mockData/reactions";
import {CustomerReaction} from "../../../types/customerReaction";
import {useSideContent} from "../../../hooks/useSideContent";

const cols = 3;
const AutoWidthGridLayout = WidthProvider(GridLayout);

export function Dashboard() {
    const margin = 10;
    const sideContentSize = 400;
    const {sideContent, openSideContent, closeSideContent} = useSideContent();

    const initLayout = useMemo<Layout[]>(() => {
        try {
            const data = JSON.parse(localStorage.getItem('dashboard-layout') ?? '');

            if (data.version !== 1) {
                throw new Error();
            }

            return data.layout;
        } catch {
            return [
                { i: 'a', x: 0, y: 0, w: 1, h: 4, minH: 4 },
                { i: 'b', x: 1, y: 0, w: 1, h: 4, minH: 4 },
                { i: 'c', x: 2, y: 0, w: 1, h: 4, minH: 4 },
                { i: 'd', x: 0, y: 4, w: 3, h: 6, minH: 4 },
                { i: 'e', x: 0, y: 8, w: 2, h: 6, minH: 4 },
            ];
        }
    }, []);

    const [layout, setLayout] = useState(initLayout);

    useEffect(() => {
        localStorage.setItem(
            'dashboard-layout',
            JSON.stringify({ version: 1, layout }),
        );
    }, [layout]);

    const openDetail = (item: CustomerReaction) => {
        openSideContent(item, sideContentSize + '%');
    }

    const closeDetail = () => {
        closeSideContent();

        setSelectedReaction(null);
    }

    const {isLoading, reactions, selectedReaction, tableDataMapping, setSelectedReaction} = useCustomerReactions();

    useEffect(() => {
        if (selectedReaction?.ACCESSIBILITYID) {
            openDetail(selectedReaction);
        }
    }, [selectedReaction])

    return (
        <>
            <div className={dashboardStyle.gridContent}>
                <div className={dashboardStyle.innerContainer} style={{
                    width: sideContent.isOpen ? 'calc(100% - '+ sideContentSize + 'px )' : '100%'
                }}>
                    <AutoWidthGridLayout
                        className="layout"
                        layout={layout}
                        cols={cols}
                        rowHeight={50}
                        margin={[margin * 2, margin * 2]}
                        draggableHandle=".a-drag-handle"
                        onLayoutChange={setLayout}
                        // preventCollision
                    >
                        <DraggableCard
                            key="a"
                            icon='codicon codicon-briefcase'
                            caption={'Companies - table example'}
                            content0={() => {
                                return isLoading ? <KolSpin _show={true} /> :
                                    <KolTable
                                        _label={""}
                                        _data={reactions}
                                        _headers={tableDataMapping}
                                        style={{
                                            fontSize : "12px"
                                        }} />

                            }

                            }></DraggableCard>
                        <DraggableCard
                            key="b"
                            icon={'codicon codicon-info'}
                            caption={'Paragraph example'}
                            content0={() => (
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                    elit, sed do eiusmod tempor incididunt ut labore et
                                    dolore magna aliqua. Rhoncus dolor purus non enim
                                    praesent elementum facilisis leo vel. Risus at
                                    ultrices mi tempus imperdiet. Semper risus in
                                    hendrerit gravida rutrum quisque non tellus.
                                    Convallis convallis tellus id interdum velit laoreet
                                    id donec ultrices. Odio morbi quis commodo odio
                                    aenean sed adipiscing. Amet nisl suscipit adipiscing
                                    bibendum est ultricies integer quis. Cursus euismod
                                    quis viverra nibh cras. Metus vulputate eu
                                    scelerisque felis imperdiet proin fermentum leo.
                                    Mauris commodo quis imperdiet massa tincidunt. Cras
                                    tincidunt lobortis feugiat vivamus at augue. At
                                    augue eget arcu dictum varius duis at consectetur
                                    lorem. Velit sed ullamcorper morbi tincidunt. Lorem
                                    donec massa sapien faucibus et molestie ac.
                                </p>

                            )}></DraggableCard>
                        <DraggableCard
                            key="c"
                            icon={'codicon codicon-info'}
                            caption={'Paragraph 1'}
                            content0={() => (
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                    elit, sed do eiusmod tempor incididunt ut labore et
                                    dolore magna aliqua. Rhoncus dolor purus non enim
                                    praesent elementum facilisis leo vel. Risus at
                                    ultrices mi tempus imperdiet. Semper risus in
                                    hendrerit gravida rutrum quisque non tellus.
                                    Convallis convallis tellus id interdum velit laoreet
                                    id donec ultrices. Odio morbi quis commodo odio
                                    aenean sed adipiscing. Amet nisl suscipit adipiscing
                                    bibendum est ultricies integer quis. Cursus euismod
                                    quis viverra nibh cras. Metus vulputate eu
                                    scelerisque felis imperdiet proin fermentum leo.
                                    Mauris commodo quis imperdiet massa tincidunt. Cras
                                    tincidunt lobortis feugiat vivamus at augue. At
                                    augue eget arcu dictum varius duis at consectetur
                                    lorem. Velit sed ullamcorper morbi tincidunt. Lorem
                                    donec massa sapien faucibus et molestie ac.
                                </p>

                            )}></DraggableCard>
                        <DraggableCard
                            key="d"
                            icon={'codicon codicon-info'}
                            caption={'Paragraph 2'}
                            content0={() => (
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                    elit, sed do eiusmod tempor incididunt ut labore et
                                    dolore magna aliqua. Rhoncus dolor purus non enim
                                    praesent elementum facilisis leo vel. Risus at
                                    ultrices mi tempus imperdiet. Semper risus in
                                    hendrerit gravida rutrum quisque non tellus.
                                    Convallis convallis tellus id interdum velit laoreet
                                    id donec ultrices. Odio morbi quis commodo odio
                                    aenean sed adipiscing. Amet nisl suscipit adipiscing
                                    bibendum est ultricies integer quis. Cursus euismod
                                    quis viverra nibh cras. Metus vulputate eu
                                    scelerisque felis imperdiet proin fermentum leo.
                                    Mauris commodo quis imperdiet massa tincidunt. Cras
                                    tincidunt lobortis feugiat vivamus at augue. At
                                    augue eget arcu dictum varius duis at consectetur
                                    lorem. Velit sed ullamcorper morbi tincidunt. Lorem
                                    donec massa sapien faucibus et molestie ac.
                                </p>

                            )}></DraggableCard>
                        <DraggableCard
                            key="e"
                            icon={'codicon codicon-briefcase'}
                            caption={'Paragraph 3'}
                            content0={() => (
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                    elit, sed do eiusmod tempor incididunt ut labore et
                                    dolore magna aliqua. Rhoncus dolor purus non enim
                                    praesent elementum facilisis leo vel. Risus at
                                    ultrices mi tempus imperdiet. Semper risus in
                                    hendrerit gravida rutrum quisque non tellus.
                                    Convallis convallis tellus id interdum velit laoreet
                                    id donec ultrices. Odio morbi quis commodo odio
                                    aenean sed adipiscing. Amet nisl suscipit adipiscing
                                    bibendum est ultricies integer quis. Cursus euismod
                                    quis viverra nibh cras. Metus vulputate eu
                                    scelerisque felis imperdiet proin fermentum leo.
                                    Mauris commodo quis imperdiet massa tincidunt. Cras
                                    tincidunt lobortis feugiat vivamus at augue. At
                                    augue eget arcu dictum varius duis at consectetur
                                    lorem. Velit sed ullamcorper morbi tincidunt. Lorem
                                    donec massa sapien faucibus et molestie ac.
                                </p>

                            )}></DraggableCard>
                    </AutoWidthGridLayout>
                </div>
                <div className={`${dashboardStyle.sideContent} ${!sideContent.isOpen ? dashboardStyle.hidden : null}`}>
                    <div className={dashboardStyle.innerContainer}>
                        {sideContent.item ?
                            <CustomerDetail reaction={sideContent.item} onCloseClick={closeDetail} />
                            : ''}
                    </div>
                </div>
            </div>
        </>
    )
}