import {ReactElement, useState} from "react";
import {Link} from "react-router-dom";
import style from "./MultiMenuList.module.css";
import {KolIcon} from "@public-ui/react";

type LinkCallback = () => void;

type MenuItem = {
    label: string | ReactElement,
    href: string | LinkCallback,
    title?: string,
    icon?: string | ReactElement,
    items?: MenuItem[] | null,
};

type Props = {
    items: MenuItem[],
    parent?: MenuItem | null,
    collapsed?: boolean
    maxLevel?: number,
    className?: string | null,
    name?: string | null,
    level?: number,
    isCollapsible?: boolean,
    fontSize?: string
};

export function MultiMenuList(
    {
        items,
        parent  = null,
        maxLevel = 1,
        className,
        collapsed = true,
        level = 0,
        isCollapsible = true
    } : Props) {

    const [isCollapsed, setIsCollapsed] = useState(isCollapsible ? collapsed : false);

    const getIcon = (icon: string | ReactElement | undefined | null) => {
        if (!icon) {
            return;
        }

        if (typeof icon == 'string') {
            return <KolIcon _icons={icon} />
        }

        return icon;
    };

    const getItem = (item: MenuItem, level: number = 0) => {
          if (level > maxLevel) {
              return;
          }

          if (item.items) {
              return (
                  <li key={`${item.label}_${level}`}>
                      <MultiMenuList
                          className={className ? className : null}
                          items={item.items} collapsed={false}
                          level={level + 1}
                          isCollapsible={isCollapsible}
                          parent={item}
                      />
                  </li>
              );
          }

          if (typeof item.label == 'string') {
              return (
                  <li key={`${item.label}_${level}`}>
                      {typeof item.href == 'string'
                          ? <Link to={item.href} title={item.title}>
                              {item.icon ? getIcon(item.icon) : null}
                              {item.label}
                          </Link>
                          : <Link to={'#'} onClick={item.href} title={item.title}>
                              {item.icon ? getIcon(item.icon) : null}
                              {item.label}
                          </Link>
                      }
                  </li>
              );
          }
          return (
              <li>
                  {item.label}
              </li>

          )
    };

    const getList = (list: MenuItem[], level: number = 0, isCollapsed: boolean = false) => {
        if (level > maxLevel) {
            return;
        }

        const isOdd = Math.abs(level % 2) == 1;

        return (
            <ul className={`${!isOdd ? style.main : style.sub} ${isCollapsed ? style.collapsed : style.hidden}`}>
                {list.map((item) => getItem(item))}
            </ul>
        )
    };

    return (
        <div className={`${style.multiMenuList} ${className ? className : ''}`}>
            {parent ? <span onClick={() => isCollapsible ? setIsCollapsed(!isCollapsed) : null}>
                {getIcon(parent.icon)}
                {parent.label}
            </span> : ''}
            {getList(items, level, isCollapsed)}
        </div>
    )
}