import style from "./CustomerReactionList.module.css";
import {Card} from "../../../components/general/card/Card";
import {KolButton, KolForm, KolInputCheckbox, KolInputDate, KolInputText, KolSelect} from "@public-ui/react";
import {useNavigate} from "react-router-dom";
import addReactionStyle from './AddReaction.module.css';
import {AddCustomerReactionForm} from "../../../components/forms/customer-reaction/AddCustomerReactionForm";
import {CustomerReaction} from "../../../types/customerReaction";
import {useCustomerReactions} from "../../../hooks/useCustomerReactions";

export function AddReaction() {
    const navigate = useNavigate();
    const handleNavigateBack = () => {
        navigate('/manager/customer');
    };

    const {saveCustomerReaction} = useCustomerReactions();

    const handleFormData = async (data: CustomerReaction) => {
        let d = {...data};
        d.DATEOFRECEIPT = (Date.parse(data.DATEOFRECEIPT) / 1000).toString();

        try {
            const r = await saveCustomerReaction(d);
            handleNavigateBack();
        } catch (e) {

        }
    };

    return (
        <div className={addReactionStyle.addReaction}>
            <div className={style.content}>
                <Card>
                    <KolButton
                        _label={''}
                        _hideLabel={true}
                        _variant={'primary'}
                        _icons={'codicon codicon-arrow-left'}
                        _on={{
                            onClick: handleNavigateBack
                        }}
                        className={style.addButton}
                    />

                    <AddCustomerReactionForm onCancelClick={handleNavigateBack} onFormSubmit={handleFormData} />
                </Card>
            </div>
        </div>
    );
}