import {ReactElement, ReactNode, useState} from "react";
import style from './Tabs.module.css';

type Tab = {
    label: string,
    content: string | ReactNode | ReactElement
};

type Props = {
    tabs: Tab[],
    className?: string
};

export function Tabs({tabs, className}: Props) {
    const [tab, setTab] = useState<Tab | null>(
        tabs.length > 0 ? tabs[0] : null
    );

    return (
        <div className={`${style.tabs} ${className ? className : ''}`}>
            <div className={`${style.tabHeader} tab-header`}>
                <ul>
                    {tabs.map((item) => {
                        return <li className={
                            tab?.label == item.label ? style.active : ''
                        } onClick={() => {
                            setTab(item)
                        }}>{item.label}</li>
                    })}
                </ul>
            </div>
            <div className={`${style.tabContent} tab-content`}>
                {tabs.map((item) => {
                    return <div className={tab?.label == item.label ? style.active : style.inactive}>
                        {item.content}
                    </div>
                })}
            </div>
        </div>
    );
}