import {KolButton, KolSpin, KolTable} from "@public-ui/react";
import {customerReactions} from "../../../mockData/reactions";
import {DraggableCard} from "../../../components/general/card/DraggableCard";
import {useCustomerReactions} from "../../../hooks/useCustomerReactions";
import style from './CustomerReactionList.module.css';
import dashboardStyle from "../dashboard/Dashboard.module.css";
import {useSideContent} from "../../../hooks/useSideContent";
import {CustomerDetail} from "./CustomerDetail";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export function CustomerReactionList() {
    const {isLoading, reactions, selectedReaction, tableDataMapping, setSelectedReaction} = useCustomerReactions();
    const {sideContent, openSideContent, closeSideContent} = useSideContent();
    const navigate = useNavigate();
    const sideContentSize = 400;

    useEffect(() => {
        if (selectedReaction?.ACCESSIBILITYID) {
            openSideContent(selectedReaction, sideContentSize + '%');
        }
    }, [selectedReaction]);

    return (
        <>
            <div className={style.content}>
                <div className={style.innerContent} style={{
                    width: sideContent.isOpen ? 'calc(100% - ' + sideContentSize + 'px )' : '100%'
                }}>
                    <DraggableCard
                        key="a"
                        icon='codicon codicon-briefcase'
                        caption={'Companies - table example'}
                        content0={() => {
                            return isLoading ? <KolSpin _show={true}/> :
                                <>
                                    <KolButton
                                        _label={''}
                                        _hideLabel={true}
                                        _variant={'primary'}
                                        _icons={'codicon codicon-add'}
                                        _on={{
                                            onClick: () => {
                                                navigate('/manager/customer/add');
                                            }
                                        }}
                                        className={style.addButton}
                                    />
                                    <KolTable
                                        _label={""}
                                        _data={reactions}
                                        _headers={tableDataMapping}
                                        style={{
                                            fontSize: "12px"
                                        }}/>
                                </>

                        }
                        }></DraggableCard>
                </div>
                <div className={`${dashboardStyle.sideContent} ${!sideContent.isOpen ? dashboardStyle.hidden : null} ${style.sideContent}`}>
                    <div className={dashboardStyle.innerContainer}>
                        {sideContent.item ?
                            <CustomerDetail reaction={sideContent.item} onCloseClick={closeSideContent} />
                            : ''}
                    </div>
                </div>
            </div>
        </>
    );
}