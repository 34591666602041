import {useEffect, useState} from "react";
import {set} from "react-hook-form";

type Error = {
    field: string,
    message: string
}

type ErrorType = Record<string, Error>;

export function useFormErrors() {
    const [errors, setErrors] = useState<ErrorType>({});

    useEffect(() => {
        console.log(errors);
    }, [errors]);

    const addError = (field: string, error: string) => {
        const e = errors;

        e[field] = {
            field: field,
            message: error
        };

        setErrors(e);
    };

    const clearError = (field: string) => {
        let error = errors[field] ?? null;

        if (error) {
            delete errors[field];
            console.log(errors);
            setErrors(errors);
        }
    };

    const getError = (field: string) => {
        // const e = errors?.find((error) => console.log(error));

        return errors[field] ? errors[field].message : '';
    };

    const hasError = () => {

    };

    return {
        addError,
        clearError,
        getError,
        errors
    }
}