import style from "./DropDownButton.module.css";
import React, {ReactElement, ReactNode, RefObject, useEffect, useRef, useState} from "react";
import {KolButton} from "@public-ui/react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {useOutsideClick} from "../../../hooks/useOutsideClick";

export enum Position {
    BOTTOM,
    OVER,
    LEFT,
    RIGHT
}

type Offset = {
    left: string | null,
    top: string | null,
    right: string | null,
    bottom: string | null,
    transform: string,
};

type Props = {
    className?: string,
    label?: string,
    children: ReactNode,
    open?: boolean,
    button?: ReactElement,
    position?: Position,
    closeOnContentClick?: boolean
};

export function DropDownArea({label,  button, children, position, className, closeOnContentClick} : Props) {
    if (closeOnContentClick === undefined) {
        closeOnContentClick = false;
    }

    if (!position) {
        position = Position.BOTTOM;
    }

    if (!label) {
        label = 'button';
    }

    const [open, setOpen] = useState(false);
    const [offset, setOffset] = useState<Offset | null>(null);
    const {windowWidth, windowHeight} = useWindowDimensions();

    const contentRef = useRef<HTMLDivElement>(null);
    const dropDownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef(null);
    const {isOutsideClick, setIsOutsideClick} = useOutsideClick(dropDownRef);

    const toggleVisibility = () => {
        setOpen(!open);
    };

    const getButton = () => {
        if (button) {
            return React.cloneElement(button, {
                onClick: toggleVisibility, // custom elements must provide onClick event / callback
                _on: { // kolibri elements
                    onClick: toggleVisibility
                },
                ref: buttonRef
            })
        }

        // setup default label if none is provided
        if (!label) {
            label = 'button';
        }

        return (
            <KolButton _label={label} _variant={"primary"} _on={{
                onClick: toggleVisibility
            }}
            ref={buttonRef}
            />
        );
    };

    const getBottomOffset = (contentRef: RefObject<HTMLDivElement>, dropDownRef: RefObject<HTMLDivElement>) => {
        const elementLeft = dropDownRef.current?.offsetLeft ?? 0;
        const elementWidth = contentRef.current?.offsetWidth ?? 0;
        const elementPosition = elementLeft + elementWidth;

        let offset = {
            left: '0',
            top: '0',
            right: null,
            bottom: null
        } as Offset;

        offset.left = '50%';
        offset.transform = 'translateX(-50%)'

        if (elementPosition > windowWidth) { // out of bounds from right side
            offset.left = (elementWidth - (dropDownRef.current?.offsetWidth ?? 0)) * -1 + 'px'; // lets pull element
            offset.transform = 'inherit';
        }

        offset.top = (dropDownRef.current?.offsetHeight && dropDownRef.current.offsetHeight > 0)
            ? dropDownRef.current.offsetHeight + 'px' : 'inherit';

        return offset;
    };

    const getOverOffset = (dropDownRef: RefObject<HTMLDivElement>) => {
        let offset = {
            left: null,
            top: '10px',
            right: '0px',
            bottom: null
        } as Offset;

        const width = dropDownRef.current?.offsetWidth ?? 0;

        offset.right = (width / 2) + 'px';

        return offset;
    };

    const getOffset = (contentRef: RefObject<HTMLDivElement>, dropDownRef: RefObject<HTMLDivElement>) => {
        if (position === Position.BOTTOM) {
            return getBottomOffset(contentRef, dropDownRef);
        }

        if (position === Position.OVER) {
            return getOverOffset(dropDownRef);
        }

        return {} as Offset;
    };

    const handleContentClick = () => {
        if (closeOnContentClick) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (open && contentRef.current !== null && dropDownRef.current !== null) {
            if (!offset && contentRef.current !== null && dropDownRef.current !== null) {
                const of = getOffset(contentRef, dropDownRef);
                setOffset(of);
            }
        }

        console.log('size of content', contentRef?.current?.offsetWidth);

    }, [open]);

    useEffect(() => {
        if (open && isOutsideClick) {
            setOpen(false);
        }
    }, [isOutsideClick])

    return (
        <div className={`${style.aditoDropDownButton} ${className}`} ref={dropDownRef}>
            <div style={{
                display: "table"
            }}>
                {getButton()}
            </div>
            <div
                className={`${style.aditoDropDownContent} ${open ? style.show : style.hidden}`}
                ref={contentRef}
                style={{
                    left: offset?.left ? offset.left : 'inherit',
                    top: offset?.top ? offset.top : 'inherit',
                    right: offset?.right ? offset.right : 'inherit',
                    bottom: offset?.bottom ? offset.bottom : 'inherit',
                    transform: offset?.transform ?? 'inherit'
                }}
                onClick={handleContentClick}
            >
                {children}
            </div>
        </div>
    );
}