
export const languages = [
    {
        label: 'English',
        value: 'en'
    },
    {
        label: 'Deustch',
        value: 'de'
    },
    {
        label: 'Español',
        value: 'es'
    },
    {
        label: 'Français',
        value: 'fr'
    },
    {
        label: 'Polski',
        value: 'pl'
    }
];