import {useState} from "react";
import useTheme from "./useTheme";

export function useAccessibility() {
    const {theme} = useTheme();
    const [accesibility, setAccessibility] = useState((theme !== 'default') ? true : false);

    const handleAccesibilityChange = (event: unknown) => {
        let state = !accesibility;
        if (state) {
            window.dispatchEvent(new CustomEvent('theme-changed', {
                detail: 'adito'
            }));


            setAccessibility(state);
            return;
        }

        window.dispatchEvent(new CustomEvent('theme-changed', {
            detail: 'default'
        }));

        setAccessibility(state);
    };

    return {accesibility, handleAccesibilityChange};
}