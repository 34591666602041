import {
    ComponentProps,
    forwardRef,
    KeyboardEventHandler,
    ReactNode,
    useState,
} from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import dcStyle from './DraggableCard.module.css';
import {KolIcon, KolLink} from "@public-ui/react";
import {Link} from "react-router-dom";

type Props = {
    content0(toggled: boolean): ReactNode;
    icon: ReactNode | string;
    caption: string;
    toggleable?: boolean;
    onHandleKeyDown?: KeyboardEventHandler<HTMLDivElement>;
};

export const DraggableCard = forwardRef<
    HTMLDivElement,
    ComponentProps<'div'> & Props
    >(
    (
        {
            content0,
            children,
            style,
            className,
            icon,
            caption,
            toggleable,
            onHandleKeyDown,
            ...rest
        },
        ref,
    ) => {
        const [toggled, setToggled] = useState(false);

        const getIcon = (icon: ReactNode | string) => {
            if (typeof icon === 'string') {
                return <KolIcon _icons={icon} />
            }

            return icon;
        };

        return (
            <div style={style} className={`${className} ${dcStyle.card}`} ref={ref} {...rest}>
                <section>
                    <div className={`a-drag-handle ${dcStyle.header} card-header`}>
                        <div>
                            {getIcon(icon)}
                        </div>
                        <h4>{caption}</h4>
                        <div className={dcStyle.controls}>
                            <Link to={'#'}>
                                {getIcon('codicon codicon-kebab-vertical')}
                            </Link>
                            <Link to={'#'}>
                                {getIcon('codicon codicon-close')}
                            </Link>

                        </div>
                    </div>
                    <div className={dcStyle.content}>
                        {content0(toggled)}
                    </div>
                </section>
                {children}
            </div>
        );
    },
);

DraggableCard.displayName = 'DragableCard';
