import {useEffect, useState} from "react";
import {KoliBriTableHeaders} from "@public-ui/components";
import {getRoot} from "../utils/componentUtils";
import {KolLink} from "@public-ui/react";
import {Avatar} from "../components/general/avatar/Avatar";
import {useNavigate} from "react-router-dom";
import {CustomerReaction} from "../types/customerReaction";
import {encode} from 'base-64';

export function useCustomerReactions() {
    const user = 'CustomerReactionWebservice';
    const password = 'adito';
    const apiUrl = 'https://accessibilityrest.ourdevops.io/services/rest/CustomerReaction_webservice';

    const [reactions, setReactions] = useState<CustomerReaction[]>({} as CustomerReaction[]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedReaction, setSelectedReaction] = useState<CustomerReaction | null>({} as CustomerReaction)
    const navigate = useNavigate();

    useEffect(() => {
        const headers = new Headers();
        headers.set('Authorization', 'Basic ' + encode(user + ':' + password))
        fetch(apiUrl, {
            headers: headers
        })
            .then((response) => response.json())
            .then((data: CustomerReaction[]) => {
                setIsLoading(false);
                setReactions(data);
            }).catch((e) => {
            setIsLoading(false);
        });

    }, []);

    const renderFirstName = (_el: any, _t: any, item: any) => {
        item = item as CustomerReaction;
        const link = '/manager/customer/' + item.ACCESSIBILITYID;

        getRoot(_el).render(
            <KolLink _href="#" _label={item.FIRSTNAME} _on={{
                onClick: () => navigate(link)
            }}/>
        );
    }

    const renderLastName = (_el: any, _t: any, item: any) => {
        item = item as CustomerReaction;
        const link = '/manager/customer/' + item.ACCESSIBILITYID;

        getRoot(_el).render(
            <KolLink _href="#" _label={item.LASTNAME} _on={{
                onClick: () => navigate(link)
            }}/>
        );
    }

    const renderAvatar = (_el: any, _t: any, item: any) => {
        getRoot(_el).render(
            <Avatar name={item.FIRSTNAME + ' ' + item.LASTNAME} onClick={() => {
                setSelectedReaction(item);
            }}/>
        );
    }

    const saveCustomerReaction = async (data: CustomerReaction) => {
        delete data["ACCESSIBILITYID"];

        let response = await fetch(apiUrl, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encode(user + ':' + password)
            },
            method: "POST",
            body: JSON.stringify(data)
        });

        console.log(response);
    };

    const tableDataMapping: KoliBriTableHeaders = {
        horizontal: [[
            {label: "Picture", key: 'id', render: renderAvatar},
            {label: "Customer reaction code", key: 'ACCESSIBILITYID'},
            {label: "First name", key: 'FIRSTNAME', render: renderFirstName},
            {label: "Last name", key: "LASTNAME", render: renderLastName},
            {label: "Date of reciept", key: "DATEOFRECEIPT"},
            {label: "Circle of people", key: "CIRCLEOFPEOPLE"},
            {label: "Customer reaction type", key: "CUSTOMERREACTIONTYPE"},
            {label: "Customer reactio status", key: "CUSTOMERREACTIONSTATUS"},
            {label: "City", key: "PLACE"},
            {label: "Zip Code", key: "ZIPCODE"}
        ]]
    };

    return {
        isLoading, reactions, selectedReaction, tableDataMapping, setSelectedReaction, saveCustomerReaction
    };
}