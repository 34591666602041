import React from "react";
import {Manager} from "./Manager";
import {RouteObject} from "react-router-dom";
import {Dashboard} from "./dashboard/Dashboard";
import {Customer} from "./customer/Customer";
import {Favorites} from "./favorites/Favorites";
import {CustomerReactionList} from "./customer/CustomerReactionList";
import {AddReaction} from "./customer/AddReaction";

export const MANAGER_ROUTES = {
    path: '/manager',
    element: (
        <Manager />
    ),
    children: [
        {
            path: '',
            element: (
                <Dashboard />
            )
        },
        {
            path: 'customer',
            element: (
                <CustomerReactionList />
            ),
        },
        {
            path: 'customer/add',
            element: (
                <AddReaction />
            )
        },
        {
            path: 'customer/:id',
            element: (
                <Customer />
            )
        },
        {
            path: 'favorites',
            element: (
                <Favorites />
            )
        }
    ]
} as RouteObject