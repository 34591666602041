import { createValueContext } from './useContextValue';

const auth = createValueContext(
  () => window.localStorage.getItem('acs-auth') === 'true',
  (value) =>
    window.localStorage.setItem('acs-auth', JSON.stringify(value)),
);

export const AuthProvider = auth.ContextValueProvider;
export const useAuth = auth.useContextValue;
