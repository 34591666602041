import style from './SignIn.module.css';
import {
    KolButton,
    KolForm,
    KolIcon,
    KolInputCheckbox,
    KolInputPassword,
    KolInputText,
    KolSelect
} from "@public-ui/react";
import {useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import useTheme from "../../hooks/useTheme";
import AditoLayout from "../../AditoLayout";
import {languages} from "../../mockData/languages";
import {ListBox} from "../../components/general/listBox/ListBox";
import {useAccessibility} from "../../hooks/useAccessibility";

export default SignIn;

type Credentials = {
    username: string,
    password: string
}

export function SignIn() {
    const {accesibility, handleAccesibilityChange} = useAccessibility();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { setValue: setAuthenticated } = useAuth();

    const handleSignIn = (event: React.FormEvent<HTMLFormElement>) => {
        setAuthenticated(true);
    };

    return (
        <AditoLayout>
            <div className={style.bg}>
                <div className={style.loginMenu}>
                    <div className={style.item}>
                        <KolIcon _label="Zu Hause" _icons="codicon codicon-globe"></KolIcon>
                        <KolInputCheckbox
                            _value="bmf" _variant="switch" _label=""
                            _on={{
                                onChange: handleAccesibilityChange
                            }}
                            _checked={accesibility}
                        />
                    </div>
                    <div className={style.item}>
                        <KolIcon _label="Zu Hause" _icons="codicon codicon-globe"></KolIcon>
                        <ListBox items={languages}></ListBox>
                    </div>
                    <div className={style.item}>
                        <KolIcon _label="Zu Hause" _icons="codicon codicon-watch"></KolIcon>
                        <ListBox items={[
                            {
                                label: 'Europe/Bratislava',
                                value: 'Europe/Bratislava'
                            },
                            {
                                label: 'Europe/London',
                                value: 'Europe/London'
                            }
                        ]}></ListBox>
                    </div>
                </div>
                <div className={style.content}>
                    <div className={style.loginBox}>
                        <div className={style.loginHeader}>
                            <img src="/images/logo.svg" alt="Adito" width="400" />
                        </div>
                        <div className={style.loginContent}>
                            <div>

                            </div>
                            <form onSubmit={handleSignIn}>
                                <div className={style.formContent}>
                                    <KolInputText
                                        _name={'name'}
                                        _placeholder={'Username'}
                                        _on={{
                                        onChange: (e: Event) => {
                                            const t = e.target as HTMLInputElement;

                                            setUsername(t.value);
                                        }
                                    }}/>
                                    <KolInputPassword
                                        _name={'password'}
                                        _placeholder={'Password'}
                                        _on={{
                                        onChange: (e: Event) => {
                                            const t = e.target as HTMLInputElement;

                                            setPassword(t.value);
                                        }
                                    }} />
                                    <KolInputCheckbox _variant="switch" _label="Stay signed" _icons="fa-solid fa-user"/>
                                    <KolButton _type="submit" _label="Sign In" _variant="primary" className={style.button}/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </AditoLayout>
    );
}