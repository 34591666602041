import {Link, Outlet} from "react-router-dom";
import style from './manager.module.css';
import {KolButton, KolIcon} from "@public-ui/react";
import {useAuth} from "../../hooks/useAuth";
import {Avatar} from "../../components/general/avatar/Avatar";
import {DropDownArea, Position} from "../../components/general/drop-down/DropDownArea";
import {MultiMenuList} from "../../components/general/navigation/MultiMenuList";

export function Manager() {
    const { setValue: setAuthenticated } = useAuth();

    return (
        <div className={style.main}>
            <div className={style.navBar}>
                <header className={style.headerNavigation}>
                    <Link to="/home" className={style.headerNavigationLinkActive}>
                        <KolIcon _icons={"codicon codicon-home"} className={style.headerNavigationIcon}/>
                    </Link>
                    <Link to="/calendar">
                        <KolIcon _icons={"codicon codicon-calendar"} className={style.headerNavigationIcon}/>
                    </Link>
                    <Link to="/coffee">
                        <KolIcon _icons={"codicon codicon-bell"} className={style.headerNavigationIcon}/>
                    </Link>
                </header>
                <ul className={style.navMenu}>
                    <li>
                        <Link to="/manager/customer">
                            <KolIcon _icons={"codicon codicon-book"} className={style.headerNavigationIcon}/>
                            Customer reactions
                        </Link>
                    </li>
                    <li>
                        <Link to="/manager/favorites">
                            <KolIcon _icons={"codicon codicon-star-empty"} className={style.headerNavigationIcon}/>
                            Task
                        </Link>
                    </li>
                </ul>
            </div>

            <div className={style.contentContainer}>
                <div className={style.topNavigation}>
                    <div className={style.topBar}>
                        <div className={style.contactMenu}>
                            {/*<DropDownArea*/}
                            {/*    button={*/}
                            {/*        <KolButton*/}
                            {/*            _label={"Contact management"}*/}
                            {/*            _variant={'primary'}*/}
                            {/*            _icons={'codicon codicon-call-outgoing'}*/}
                            {/*        />*/}
                            {/*    }*/}
                            {/*    closeOnContentClick={false}*/}
                            {/*>*/}
                            {/*    <MultiMenuList items={[*/}
                            {/*        {*/}
                            {/*            href: '/settings',*/}
                            {/*            label: 'Settings',*/}
                            {/*            icon: 'codicon codicon-gear',*/}
                            {/*            items: [*/}
                            {/*                {*/}
                            {/*                    href: '/settings',*/}
                            {/*                    label: 'Settings',*/}
                            {/*                    icon: 'codicon codicon-gear'*/}
                            {/*                },*/}
                            {/*                {*/}
                            {/*                    href: '/help',*/}
                            {/*                    label: 'Help',*/}
                            {/*                    icon: 'codicon codicon-question'*/}
                            {/*                },*/}
                            {/*                {*/}
                            {/*                    href: () => setAuthenticated(false),*/}
                            {/*                    label: 'Log out',*/}
                            {/*                    icon: 'codicon codicon-sign-out'*/}
                            {/*                }*/}
                            {/*            ]*/}
                            {/*        },*/}
                            {/*        {*/}
                            {/*            href: '/help',*/}
                            {/*            label: 'Help',*/}
                            {/*            icon: 'codicon codicon-question'*/}
                            {/*        },*/}
                            {/*        {*/}
                            {/*            href: () => setAuthenticated(false),*/}
                            {/*            label: 'Log out',*/}
                            {/*            icon: 'codicon codicon-sign-out'*/}
                            {/*        }*/}
                            {/*    ]}*/}
                            {/*                   maxLevel={1}*/}
                            {/*                   className={style.contactMenuList}*/}
                            {/*                   isCollapsible={false}*/}
                            {/*    />*/}
                            {/*</DropDownArea>*/}
                        </div>
                        <div className={style.userMenu}>
                            <DropDownArea
                                className={style.userAvatarMenu}
                                button={<Avatar name={"Kamil Hurajt"} width={'40px'} height={'40px'} />}
                                position={Position.OVER}
                                closeOnContentClick={true}
                            >
                                <MultiMenuList items={[
                                    {
                                        href: '/settings',
                                        label: 'Settings',
                                        icon: 'codicon codicon-gear'
                                    },
                                    {
                                        href: '/help',
                                        label: 'Help',
                                        icon: 'codicon codicon-question'
                                    },
                                    {
                                        href: () => setAuthenticated(false),
                                        label: 'Log out',
                                        icon: 'codicon codicon-sign-out'
                                    }
                                ]}/>
                            </DropDownArea>

                        </div>
                    </div>
                    <div className={style.titleBar}>
                        <h1>Title</h1>
                    </div>
                </div>
                <div className={style.content}>
                    <Outlet/>
                </div>
            </div>

        </div>
    )
}