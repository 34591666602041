import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css'
import App from './App';
import reportWebVitals from './reportWebVitals';

import { register } from "@public-ui/components";
import { defineCustomElements } from '@public-ui/components/dist/loader';
import { DEFAULT, BMF, BAMF } from '@public-ui/themes';
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./hooks/useAuth";

register([DEFAULT, BMF, BAMF], defineCustomElements, {
    theme: {
        detect: 'auto'
    }
})
    .then(() => {
        const htmlDivElement: HTMLDivElement | null = document.querySelector<HTMLDivElement>('div#root');

        // if (htmlDivElement instanceof HTMLDivElement) {
        const root = ReactDOM.createRoot(
            htmlDivElement as HTMLElement
        );

        root.render(
            <BrowserRouter>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </BrowserRouter>
        );
    })
    .catch(console.warn);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
