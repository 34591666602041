import React, {ReactNode, useEffect, useState} from "react";
import useTheme from "./hooks/useTheme";

type Props = {
    children: ReactNode | ReactNode[]
}

function AditoLayout({children}: Props) {
    const {theme, setTheme} = useTheme();

    const handleThemeChanged = (theme: string) => {
        setTheme(theme);
    };

    useEffect(() => {
        window.addEventListener('theme-changed', function(e: any) {
            handleThemeChanged(e?.detail as string);
        })
    });

    return (
        <div>
            {children}
        </div>
    );
}

export default AditoLayout;