import {RefObject, useEffect, useState} from "react";

export function useOutsideClick(elementRef: RefObject<any>) {
    const [isOutsideClick, setIsOutsideClick] = useState(false);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (
                elementRef.current && !elementRef.current.contains(event.target)
            ) {
                setIsOutsideClick(true);
            } else {
                setIsOutsideClick(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    });

    return {isOutsideClick, setIsOutsideClick};
}